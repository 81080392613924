<template>
  <a class="post-item" href="" @click="$router.push({name:'single-post', params: {slug:post.post_name}})">
    <img :src="post.featured_image" :srcset="post.featured_images.large + ' 1200w, ' + post.featured_images.small + ' 300w, ' + post.featured_images.large + ' 1024w, '  + post.featured_images.medium + ' 768w, ' + post.featured_images.large + ' 1536w, ' + post.featured_image + ' 2048w, ' + post.featured_image + ' 1980w'" alt="" class="" height="675" loading="lazy"
         sizes="(max-width: 1200px) 100vw, 1200px"
         width="1200"/>
    <div :class="{featured:featured}" class="featured-posts-content">
      <span class="publish-date">
         <timeago :auto-update="60" :datetime="dateStringToDateObject(post.post_date)"></timeago> <i class="fal fa-clock"></i>
      </span>
      <h2>{{ post.post_title }}</h2>
    </div>
  </a>
</template>

<script type="text/babel">
export default {
  props: ['post', 'featured'],
  data() {
    return {
      loaded: false
    }
  },
  mounted() {

  }

}
</script>
