<template>
<div>
   <preloader v-if="!loaded">

   </preloader>
   <div class="post-content d-flex flex-nowrap flex-column" v-if="loaded">

      <figure class="post-header wp-block-image" v-if="post">
         <img :src="post.featured_image" :srcset="post.featured_image + ' 1024w, ' + post.featured_image + ' 300w, ' + post.featured_image + ' 768w, '  + post.featured_image + ' 1500w'" alt="" sizes="(max-width: 1024px) 100vw, 1024px" class="contain">
         <img :src="post.featured_image" :srcset="post.featured_image + ' 1024w, ' + post.featured_image + ' 300w, ' + post.featured_image + ' 768w, '  + post.featured_image + ' 1500w'" alt="" sizes="(max-width: 1024px) 100vw, 1024px" class="cover">
      </figure>
      <h1>{{ post.post_title }}</h1>
      <div class="post-content" v-html="post.post_content"></div>
      <meta-footer :object="post" :whitebg="true"></meta-footer>
      <related-posts :posts="related"></related-posts>
   </div>
</div>
</template>
<script type="text/babel">
import RelatedPosts from './RelatedPosts';
import MetaFooter from './MetaFooter';
import Preloader from "@/components/Preloader";

export default {
   components: {
      RelatedPosts,
      MetaFooter,
      Preloader
   },
   data() {
      return {
         post: {},
         author: {},
         related: [],
         loaded: false
      }
   },
   mounted() {
      this.getPost(this.$route.params.slug);
   },
   methods: {
      getPost: function(slug) {
         this.loaded = true;
         this.axios.post(window.base_route + '/rutger/v1/posts', {
            slug: slug
         }).then(res => {
            if (!res.data.post) {
               window.location.href = '/404';
            }
            this.post = res.data.post;
            this.author = res.data.author;
            this.related = res.data.related;
            this.loaded = true;
            setTimeout(function() {
               window.jQuery('.wp-block-gallery a').each(function() {
                  let el = window.jQuery(this);
                  el.attr('data-fancybox', 'gallery');
                  el.addClass('photo-album-preview');
               });
            }, 500);

         })
      },


   }
}
</script>
