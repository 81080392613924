<template>
<a href="" @click="$router.push({name:'login'})" class="photo-album-preview photo-album-preview-private">
   <div class="photo-album-image-wrapper">
      <img src="https://loremflickr.com/384/256/disco" height="600" loading="lazy" width="600">
      <div class="photoalbum-private-message private-message-container">
         <i class="fal fa-lock mb-1"></i>
         <strong>Deze en nog veel meer fotoalbums bekijken?</strong>
         <span>Log in of maak een gebruiker aan »</span>
      </div>
   </div>
   <span class="album-title">Deze en nog veel meer foto's</span>
   <span class="album-size">Log in of maak een gebruiker aan »</span>
</a>
</template>

<script>
export default {
   name: "PrivateItem"
}
</script>

<style scoped>

</style>
