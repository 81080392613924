<template>
  <div>
    <preloader v-if="!loaded">

    </preloader>
    <div class="container" v-if="loaded">
      <div v-if="album && album.gallery" class="single-photoalbum-container">
        <a v-for="image in album.gallery" :key="image.ID" :data-caption="image.caption" :href="image.url"
           class="photo-album-preview" data-fancybox="gallery" rel="testRelField">
          <div class="photo-album-image-wrapper">
            <img :src="image.preview_image" alt="" height="600" loading="lazy" width="600">
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";

export default {
  components: {
    Preloader
  },
  name: "PhotoAlbum.vue",
  data() {
    return {
      album: {},
      loaded: false
    }
  },
  mounted() {
    this.axios.post(window.base_route + '/rutger/v1/albums', {
      slug: this.$route.params.slug
    }).then(res => {
      this.album = res.data;
      this.loaded = true;
      window.scrollTo(0, 0);
    });
  },
  methods: {}
}
</script>

<style scoped>

</style>
