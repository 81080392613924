class Auth {
    constructor() {
        this.initUserData();

        if (this.check() && this.token !== 'null') {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }
    }

    initUserData() {

        this.token = window.cookies.get('token');
        if (!this.token) {
            this.token = window.localStorage.getItem('token');
        }

        let userData = window.cookies.get('user');
        if (!userData) {
            userData = window.localStorage.getItem('user');
        }
        this.user = userData ? userData : null;

        if (this.check(false) && this.token !== 'null') {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }
    }

    login(token, user) {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(user));
        window.cookies.set('token', token, {expires: 365});
        window.cookies.set('user', user, {expires: 365});

        if (token !== null) {
            window.axios.defaults.headers.common = {
                "Authorization": 'Bearer ' + token,
            }
        }

        this.token = token;
        this.user = user;


        // window.axios.post(window.base_route + '/rutger/v1/register-fingerprint', {
        //     fingerprint: window.visitorId,
        //     token: token,
        //     email: user.user_email
        // });

        return window.axios.post(window.base_route + '/rutger/v1/me', {email: user.user_email}).then(res => {
            user = res.data;
            window.localStorage.setItem('user', JSON.stringify(user));
            window.cookies.set('user', user, {expires: 365});

            window.parent.postMessage({
                type: 'user_logged_in', value: {
                    user: user,
                    token: token
                }
            }, '*');
        });

    }

    getUser() {
        let userData = null;
        if (this.check()) {
            // userData = window.localStorage.getItem('user');
            try {
                userData = JSON.parse(window.cookies.get('user'));
            } catch (e) {
                userData = window.cookies.get('user');
            }
            if (!userData) {
                try {
                    userData = JSON.parse(window.localStorage.getItem('user'));
                } catch (ex) {
                    userData = window.localStorage.getItem('user');
                }
            }
        }
        return userData ? userData : null;
    }

    logout(tokenIsValid = true) {

        if (tokenIsValid) {
            this.token = null;
            window.localStorage.setItem('token', null);
            window.cookies.set('token', null);
            window.localStorage.setItem('user', null);
            window.cookies.set('user', null);
            this.user = null;

            window.parent.postMessage({
                type: 'user_logged_out'
            }, '*');
            if (window.localStorage.getItem('isApp') === 'true' || window.localStorage.getItem('isApp') === true) {
                location.href = '/';
                return;
            }
            window.axios.post('/api/logout')
                .then(() => {
                    location.href = '/login';
                }).catch(() => {
                location.href = '/login';
            });
        }
    }


    check(recursive = true) {

        if (this.token !== null && this.user !== null) {
            return true;
        }

        if (recursive) {
            this.initUserData();
        }

        if (this.token !== null && this.user !== null) {
            return true;
        }

        return false;
    }

}

export default Auth;
