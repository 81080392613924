<template>
<div>
  <div class="container">
    <label>Fingerprint:</label>  {{ getLocalStorageValue('visitorId') }} <br/>
    <label>Token:</label>   {{ getLocalStorageValue('token') }} <br/>
    <label>User:</label>   {{ getLocalStorageValue('user') }} <br/>
    <label>Loggedin:</label>   {{  isLoggedIn() }} <br/>
  </div>
</div>
</template>

<script>
export default {
name: "Debug",
  methods: {

    getLocalStorageValue(key) {
      return localStorage.getItem(key);
    },
    isLoggedIn() {
      return window.Auth.check();
    }
  }
}
</script>

<style scoped>

</style>
