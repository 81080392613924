<template>
  <div>
    <div class="post-overview">

      <div class="welcome-message" v-if="loggedIn">
        <h1 v-if="user">Hi {{ user.first_name ? user.first_name : user.name }}</h1>

        <h3><span v-if="today.length > 0">Vandaag <span v-if="today.length >1">zijn </span><span
            v-if="today.length ===1">is </span>
               <span v-for="usr in today" :key="usr.ID">
                  <span v-if="usr.ID !== today[0].ID"> en </span><strong>{{ usr.display_name }}</strong>
               </span>
               jarig!
            </span>
          <span v-if="tomorrow.length >0">
               Morgen <span v-if="tomorrow.length >1">zijn </span><span v-if="tomorrow.length ===1">is </span>
               <span v-for="usr in tomorrow" :key="usr.ID">
                  <span v-if="usr.ID !== tomorrow[0].ID"> en </span><strong>{{ usr.display_name }}</strong>
               </span>
               jarig!
            </span>
          <span v-if="week.length >0">
               Deze week <span v-if="week.length >1">zijn </span><span v-if="week.length ===1">is </span>
               <span v-for="usr in week" :key="usr.ID">
                  <span v-if="usr.ID !== week[0].ID"> en </span><strong>{{ usr.display_name }}</strong>
               </span>
               jarig!
            </span>Hieronder staan de overige updates voor je klaar.
        </h3>
      </div>
      <div v-if="featured_posts" class="featured-posts featured-posts-home">
        <post-item v-for="post in featured_posts" :key="post.id" :featured="true" :post="post"></post-item>
        <private-item v-if="!loggedIn"></private-item>
        <post-item v-for="post in posts" :key="post.id" :post="post"></post-item>
      </div>
      <div class="post-item-more">
        <a href="" @click="$router.push({name:'posts'})"><i class="fal fa-newspaper"></i> Bekijk alle berichten
          »</a>
      </div>
      <h3 class="mt-3" v-if="visible_quick_posts.length">Ondertussen bij Rutges</h3>

      <div>
        <div v-masonry class="shortposts-wrapper" gutter="50" item-selector=".shortpost" transition-duration="0.0s">
          <div v-for="(post, i) in visible_quick_posts" :key="post.ID" v-masonry-tile class="shortpost">
            <div class="shortpost-content">
              <span class="smiley">{{ post.smiley }}</span>
              <div class="content">
                <h3>{{ post.post_title }}</h3>
                <div v-html="post.post_content"></div>
                <figure v-show="post.featured_image && post.featured_images">
                  <div>
                    <img :src="post.featured_images.original"
                         :srcset="post.featured_images.large + ' 1200w ,' + post.featured_images.medium + ' 600w'"
                         alt="" class="" loading="lazy" sizes="(max-width: 1200px) 100vw, 1200px">
                  </div>
                </figure>
                <span class="publish-date">
                        <timeago :auto-update="60" :datetime="dateStringToDateObject(post.post_date)"></timeago> <i
                    class="fal fa-clock"></i>
                     </span>
              </div>
            </div>
            <private-item-shortpost v-if="!loggedIn && (i === 0 || i%5 === 0)"></private-item-shortpost>
          </div>
        </div>
      </div>
      <infinite-loading v-show="quick_posts && quick_posts.items && quick_posts.items.length >0"
                        @infinite="infiniteHandler"></infinite-loading>
    </div>
  </div>
</template>
<script type="text/babel">
import PostItem from './PostItem';
import InfiniteLoading from 'vue-infinite-loading';
import PrivateItem from "./PrivateItem";
import PrivateItemShortpost from "./PrivateItemShortpost";

require('slick-carousel');

export default {
  components: {
    PostItem,
    PrivateItem,
    InfiniteLoading,
    PrivateItemShortpost
  },
  data() {
    return {
      featured_posts: [],
      quick_posts: [],
      posts: [],
      visible_quick_posts: [],
      scroll_step: 2,
      busy: false,
      user: null,
      post_counter: 0,
      birthdays: [],
      today: [],
      tomorrow: [],
      week: [],
      loggedIn: false
    }
  },
  mounted() {
    this.user = window.Auth.getUser();
    this.getFeaturedPosts();
    this.getQuickPosts();
    this.getBirthdays();

    if (this.user) {
      this.loggedIn = true;
    }
    window.scrollTo(0, 0);

    this.refreshPosts();
  },
  methods: {
    refreshPosts() {
      setTimeout(() => {
        this.getFeaturedPosts();
        this.getQuickPosts();
        this.getBirthdays();
        this.refreshPosts();
      }, 1000 * 60 * 30)
    },
    getQuickPosts: function () {
      this.axios.get(window.base_route + '/rutger/v1/quick-posts').then(res => {
        this.quick_posts = this.collect(res.data);
        // this.visible_quick_posts = this.collect(res.data);

        this.hevePosts();
      })
    },

    getBirthdays: function () {
      this.axios.get(window.base_route + '/rutger/v1/birthdays').then(res => {
        this.birthdays = this.collect(res.data);

        this.birthdays.each((user) => {
          let today = new Date();
          let user_date = new Date(user.date);
          let done = false;
          if (user_date.getDate() === today.getDate() && user_date.getMonth() === today.getMonth()) {
            this.today[this.today.length] = user;
            done = true;
          }
          today = today.addDays(1);
          if (user_date.getDay() === today.getDay() && user_date.getMonth() === today.getMonth() && done === false) {
            this.tomorrow[this.tomorrow.length] = user;
            done = true;
          }
          if (done === false) {
            this.week[this.week.length] = user;
          }
        })
      })
    },

    getPosts: function (amount) {
      this.axios.get(window.base_route + '/rutger/v1/posts/amount/' + amount).then(res => {
        this.posts = res.data;

        this.debounce(function () {
          window.jQuery('.featured-posts-home').slick({
            dots: true,
            infinite: true,
            speed: 700,
            mobileFirst: true,
            autoplay: true,
            autoplaySpeed: 2500,
            arrows: false,
            responsive: [{
              breakpoint: 768,
              settings: "unslick"
            }]
          });

        }, 60);
      })
    },

    getFeaturedPosts: function () {
      this.axios.get(window.base_route + '/rutger/v1/featured-posts').then(res => {
        this.featured_posts = res.data;
        let amount = 4 - this.featured_posts.length;
        if (amount > 0) {
          this.getPosts(amount);
        }
      })
    },
    infiniteHandler: function ($state) {
      this.busy = true;

      window.console.log('handler');

      setTimeout(() => {
        this.hevePosts($state);
        // this.waitForImages()

        this.busy = false;
      }, 100);
    },
    hevePosts($state) {
      try {
        for (let i = 0; i < this.scroll_step; i++) {
          let el = this.quick_posts.pop();

          if (el) {
            this.visible_quick_posts.push(el);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }


        }
      } catch (e) {

        window.console.log(e);
      }
      // var elem = document.querySelector('.short-posts');
    },

  }
}
</script>
