<template>
<div>
   <div class="user">
      <h1>Maak een account aan</h1>
      <p>Iedereen die zich op wat voor manier dan ook betrokken voelt bij Rutges, mag een account aanmaken</p>
      <div v-show="errors.length" id="login_error" class="login-message">
         <span v-for="error in errors" :key="error" v-html="error"></span>
      </div>
      <div class="user-content" v-if="loaded">
         <div class="row">
            <form id="account-form" class="col-12 col-md" method="post">
               <h3>Je naw gegevens</h3>
               <div class="form-group">
                  <label for="username">Voornaam</label>
                  <input type="text" class="form-control" name="Voornaam" v-model="user.first_name">
               </div>
               <div class="form-group">
                  <label for="username">Achternaam</label>
                  <input type="text" class="form-control" name="Achternaam" v-model="user.last_name">
               </div>
               <div class="form-group">
                  <label for="username">E-mailadres</label>
                  <input type="text" class="form-control" name="E-mailadres" v-model="user.email">
               </div>
               <div class="form-group">
                  <label for="birthday">Je geboortedatum</label>
                  <the-mask :mask="['##/##/####']" class="form-control" name="birthday" type="text" placeholder="dd/mm/yyyy" v-model="user.birth_date" required />
               </div>
            </form>
            <form class="col-12 offset-md-1 col-md" method="post">
               <h3>Je login gegevens</h3>
               <div class="form-group">
                  <label for="newpass">Je gebruikersnaam</label>
                  <input type="text" class="form-control" name="username" v-model="user.username">
               </div>
               <div class="form-group">
                  <label for="newpass">Je wachtwoord</label>
                  <input type="password" class="form-control" name="password" v-model="user.password">
               </div>
               <div class="form-group">
                  <label for="newpass">Herhaal je wachtwoord</label>
                  <input type="password" class="form-control" name="password-repeat" v-model="user.password_repeat">
               </div>
               <div class="submit mt-3">
                  <input id="wp-submit" class="btn btn-secondary" name="wp-submit" type="button" value="Account aanmaken" @click="save">
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
</template>

<script type="text/babel">
import {
   TheMask
} from 'vue-the-mask';
export default {
   components: {
      TheMask
   },
   data() {
      return {
         loaded: true,
         user: {},
         errors: []
      }
   },
   mounted() {},
   methods: {
      save() {
         this.errors = [];
         this.axios.post(window.base_route + '/rutger/v1/register', {
            user: this.user
         }).then(() => {
            let self = this;
            self.errors = ['U bent geregistreerd en wordt over een aantal seconden naar het login scherm doorgestuurd'];
            setTimeout(function() {
               self.$router.push('/login');
            }, 5000)

         }).catch((exception) => {
            if (exception.response && exception.response.data) {
               this.errors = [exception.response.data.message];
            }
         });

      }
   }
}
</script>
