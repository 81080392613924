<template>
<div class="mt-0">
   <figure class="featured-header">
      <div class="">
         <img v-if="!this.post.featured_image" alt="" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" height="540" loading="lazy" sizes="(max-width: 1440px) 100vw, 1440px" src="https://unsplash.it/1440/540?&query=office" srcset="https://unsplash.it/1440/540 1440w, https://unsplash.it/600/300 600w"
            width="1440">
         <img v-if="this.post.featured_image" :src="this.post.featured_images.large" :srcset="this.post.featured_images.large + ' 1440w,' + this.post.featured_images.medium + ' 600w'" alt="" class="attachment-post-thumbnail size-post-thumbnail wp-post-image"
            height="540" loading="lazy" sizes="(max-width: 1440px) 100vw, 1440px" width="1440">
      </div>
   </figure>
   <div v-if="post" class="wikipage wiki-overview page-content">
      <div class="wikipage-smiley">{{ post.smiley }}</div>
      <h1>{{ post.post_title }}</h1>
      <nav class="nav-breadcrumbs mb-3">
         <ol>
            <li><a href="" @click="$router.push({name:'wiki'})">Rutges wiki</a></li>
            <li v-if="post.parent"><a href="" @click="$router.push({name:'wiki-item', params: {slug:post.parent.post_name}})">{{
              post.parent.smiley
            }} {{ post.parent.post_title }}</a></li>
            <li><a href="">{{ post.smiley }} {{ post.post_title }}</a></li>
         </ol>
      </nav>

      <div v-html="parseContent(post.post_content)"></div>

      <div v-show="post.faq && post.faq.length" id="faq" class="accordion">
         <div v-for="(question, i) in post.faq" :key="question.question" class="card">
            <div :id="'accordion-heading-'+i" class="card-header">
               <span :aria-controls="'accordion-item-'+i" :data-target="'#accordion-item-'+i" aria-expanded="true" class="accordion-heading collapsed" data-toggle="collapse">
                  {{ question.question }}
               </span>
            </div>
            <div :id="'accordion-item-' + i" :aria-labelledby="'accordion-heading-' + i" class="accordion-content collapse" data-parent="#faq">
               <p>
                  {{ question.answer }}
               </p>
            </div>
         </div>

      </div>

      <meta-footer :object="post" :whitebg="false"></meta-footer>

   </div>
</div>
</template>
<script type="text/babel">
import MetaFooter from './MetaFooter';

export default {
   components: {
      MetaFooter
   },
   data() {
      return {
         post: false,
      }
   },
   mounted() {
      this.getWiki(this.$route.params.slug);
   },
   methods: {
      getWiki: function(slug) {
         this.axios.post(window.base_route + '/rutger/v1/postitem', {
            slug: slug
         }).then(res => {
            this.post = res.data;

            window.jQuery('#faq').collapse()
         })
      },
   }
}
</script>
