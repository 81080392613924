<template>
  <div class="recent_entries">
    <h4 class="text-secondary text-uppercase">Bekijk ook eens</h4>
    <ol>
      <related-post v-for="post in posts" :key="post.ID" :post="post"></related-post>
    </ol>
  </div>
</template>
<script type="text/babel">
import RelatedPost from './RelatedPost';

export default {
  components: {
    RelatedPost
  },
  props: ['posts']

}
</script>
