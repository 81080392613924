<template>
<div class="post-content d-flex flex-nowrap flex-column">
   <div class="post-content">
      <h1>🧐</h1>
      <h3>Wij kunnen de pagina niet (meer) vinden</h3>
      <p>Wij hebben ons best gedaan, maar het lijkt erop dat deze pagina niet (meer) bestaat of misschien verhuisd is. Je kunt natuurlijk altijd naar de <router-link to="/" :class="'logo'">home pagina</router-link> gaan of de zoekfunctie bovenin
         gebruiken. </p>
   </div>
</div>
</template>

<script>
export default {
   name: "PageNotFound"
}
</script>

<style scoped>

</style>
