<template>
  <div>

  </div>
</template>

<script type="text/babel">
export default {
  data() {
    return {}
  },
  mounted() {
    window.location.href = window.base_url + '/wp/wp-admin';
  },
  methods: {}
}
</script>