<template>
<li>
   <a href="" @click="$router.push({name:'single-post', params: {slug:post.post_name}})">
      <figure v-if="post.featured_image" class="entrie-media">
         <img :src="post.featured_images.small" alt="" height="110" loading="lazy" width="220">
      </figure>
      <span class="entrie-content">{{ post.post_title }}</span>
   </a>
</li>
</template>
<script type="text/babel">
export default {
   props: ['post']
}
</script>
