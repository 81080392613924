<template>
   <div>
      <preloader v-if="!loaded">

      </preloader>

      <div class="post-content d-flex flex-nowrap flex-column" v-if="loaded">
         <h1>Hi <span v-if="user">{{ user.first_name }}</span></h1>
         <p class="font-weight-normal">Op <span @click="$router.push({name:'debug'})">maandag</span> <strong>21 juni 2021</strong> starten we in je woning, op <strong>23 juli 2021</strong> zijn we klaar. Onderstaand vind je de complete planning. Deze informatie vinden
            jullie eveneens in het draaiboek welke we bij jullie achterlaten.</p>
            <div class="alert alert-dark" role="alert">
               Heb je vragen of opmerkingen? <strong>Anneli</strong> is jullie bewonersconsulente.<br>
               <a href="tel:+31612605969" class="btn btn-outline-secondary" target="_blank"><i class="fal fa-mobile-alt"></i> Bel Anneli</a>
               <a href="mailto:aheeg@rutges.nl" class="btn btn-outline-secondary" target="_blank"><i class="fal fa-paper-plane"></i> E-Mail Anneli</a>
            </div>
            <div class="werkdag" id="dag0">
               <h3 class="font-weight-light">
                  Maandag 21 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 0%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">4%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag0" aria-controls="werkzaamheden-dag0" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag0" aria-controls="checklist-dag0" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag0" aria-controls="overlast-dag0" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag0">
                     <div class="accordion" id="accordion-dag0">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag0" aria-expanded="false" aria-controls="item-1-dag0">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag0" class="collapse" data-parent="#accordion-dag0">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken. Houtrot reparatie + Panelen aanbrengen balkons. Reinigen lateien, gevels, schoorstenen en waterslangen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels
                                    en schoorstenen. Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag0" aria-expanded="false" aria-controls="item-2-dag0">
                                 Basis werkzaamheden binnen
                              </div>
                              <div id="item-2-dag0" class="collapse" data-parent="#accordion-dag0">
                                 <div class="card-body">
                                    Glasvervanging voor/achter. Reinigen ventilatiekanalen t.b.v. mechanische ventilatie. Aanbrengen mechanische ventilatie woning. Rookmelders aanbrengen, elektra werkzaamheden. Brandwerende strips voordeuren en
                                    bergingsdeuren. Voorzetwanden plaatsen (alleen kopgevel woningen). Vervangen intercomsysteem. Brandwerende afdichting woning/berging. Tocht profielen aanbrengen/vervangen draaiende delen. Schilderwerk binnen en
                                    buiten.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonnescherm, schotel etc. verwijderen</li>
                                       <li>Raambekleding verwijderen.</li>
                                       <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                                       <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof. Incidenteel kort geen elektra.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag0">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag0">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Raambekleding verwijderen.</li>
                        <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                        <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag1">
               <h3 class="font-weight-light">
                  Dinsdag 22 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 4%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">4%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag1" aria-controls="werkzaamheden-dag1" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag1" aria-controls="checklist-dag1" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag1" aria-controls="overlast-dag1" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag1">
                     <div class="accordion" id="accordion-dag1">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag1" aria-expanded="false" aria-controls="item-1-dag1">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag1" class="collapse" data-parent="#accordion-dag1">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken. Houtrot reparatie + Panelen aanbrengen balkons. Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels
                                    en schoorstenen. Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag1" aria-expanded="false" aria-controls="item-2-dag1">
                                 Basis werkzaamheden binnen
                              </div>
                              <div id="item-2-dag1" class="collapse" data-parent="#accordion-dag1">
                                 <div class="card-body">
                                    Glasvervanging voor/achter. Reinigen ventilatiekanalen t.b.v. mechanische ventilatie. Aanbrengen mechanische ventilatie woning. Rookmelders aanbrengen, elektra werkzaamheden. Brandwerende strips voordeuren en
                                    bergingsdeuren. Voorzetwanden plaatsen (alleen kopgevel woningen). Vervangen intercomsysteem. Brandwerende afdichting woning/berging. Tocht profielen aanbrengen/vervangen draaiende delen. Schilderwerk binnen en
                                    buiten.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonnescherm, schotel etc. verwijderen</li>
                                       <li>Raambekleding verwijderen.</li>
                                       <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                                       <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag1" aria-expanded="false" aria-controls="item-3-dag1">
                                 Toilet
                              </div>
                              <div id="item-3-dag1" class="collapse" data-parent="#accordion-dag1">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-4-dag1" aria-expanded="false" aria-controls="item-4-dag1">
                                 Badkamer
                              </div>
                              <div id="item-4-dag1" class="collapse" data-parent="#accordion-dag1">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag1">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag1">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Raambekleding verwijderen.</li>
                        <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                        <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag2">
               <h3 class="font-weight-light">
                  Woensdag 23 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 8%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">8%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag2" aria-controls="werkzaamheden-dag2" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag2" aria-controls="checklist-dag2" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag2" aria-controls="overlast-dag2" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag2">
                     <div class="accordion" id="accordion-dag2">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag2" aria-expanded="false" aria-controls="item-1-dag2">
                                 Toilet
                              </div>
                              <div id="item-1-dag2" class="collapse" data-parent="#accordion-dag2">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag2" aria-expanded="false" aria-controls="item-2-dag2">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-2-dag2" class="collapse" data-parent="#accordion-dag2">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken. Houtrot reparatie + Panelen aanbrengen balkons. Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels
                                    en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag2" aria-expanded="false" aria-controls="item-3-dag2">
                                 Basis werkzaamheden binnen
                              </div>
                              <div id="item-3-dag2" class="collapse" data-parent="#accordion-dag2">
                                 <div class="card-body">
                                    Glasvervanging voor/achter. Reinigen ventilatiekanalen t.b.v. mechanische ventilatie. Aanbrengen mechanische ventilatie woning. Rookmelders aanbrengen, elektra werkzaamheden. Brandwerende strips voordeuren en
                                    bergingsdeuren. Voorzetwanden plaatsen (alleen kopgevel woningen). Vervangen intercomsysteem. Brandwerende afdichting woning/berging. Tocht profielen aanbrengen/vervangen draaiende delen. Schilderwerk binnen en
                                    buiten.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonnescherm, schotel etc. verwijderen </li>
                                       <li>Raambekleding verwijderen.</li>
                                       <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                                       <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-4-dag2" aria-expanded="false" aria-controls="item-4-dag2">
                                 Badkamer
                              </div>
                              <div id="item-4-dag2" class="collapse" data-parent="#accordion-dag2">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag2">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag2">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Raambekleding verwijderen.</li>
                        <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                        <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag3">
               <h3 class="font-weight-light">
                  Donderdag 24 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 12%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">12%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag3" aria-controls="werkzaamheden-dag3" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag3" aria-controls="checklist-dag3" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag3" aria-controls="overlast-dag3" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag3">
                     <div class="accordion" id="accordion-dag3">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag3" aria-expanded="false" aria-controls="item-1-dag3">
                                 Basis werkzaamheden binnen
                              </div>
                              <div id="item-1-dag3" class="collapse" data-parent="#accordion-dag3">
                                 <div class="card-body">
                                    Glasvervanging voor/achter. Reinigen ventilatiekanalen t.b.v. mechanische ventilatie. Aanbrengen mechanische ventilatie woning. Rookmelders aanbrengen, elektra werkzaamheden. Brandwerende strips voordeuren en
                                    bergingsdeuren. Voorzetwanden plaatsen (alleen kopgevel woningen). Vervangen intercomsysteem. Brandwerende afdichting woning/berging. Tocht profielen aanbrengen/vervangen draaiende delen. Schilderwerk binnen en
                                    buiten.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonnescherm, schotel etc. verwijderen </li>
                                       <li>Raambekleding verwijderen.</li>
                                       <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                                       <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag3" aria-expanded="false" aria-controls="item-2-dag3">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-2-dag3" class="collapse" data-parent="#accordion-dag3">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken. Houtrot reparatie + Panelen aanbrengen balkons. Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels
                                    en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag3" aria-expanded="false" aria-controls="item-3-dag3">
                                 Toilet
                              </div>
                              <div id="item-3-dag3" class="collapse" data-parent="#accordion-dag3">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-4-dag3" aria-expanded="false" aria-controls="item-4-dag3">
                                 Badkamer
                              </div>
                              <div id="item-4-dag3" class="collapse" data-parent="#accordion-dag3">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag3">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag3">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Raambekleding verwijderen.</li>
                        <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                        <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag4">
               <h3 class="font-weight-light">
                  Vrijdag 25 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 16%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">16%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag3" aria-controls="werkzaamheden-dag4" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag4" aria-controls="checklist-dag4" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag4" aria-controls="overlast-dag4" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag4">
                     <div class="accordion" id="accordion-dag4">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag4" aria-expanded="false" aria-controls="item-1-dag4">
                                 Basis werkzaamheden binnen
                              </div>
                              <div id="item-1-dag4" class="collapse" data-parent="#accordion-dag4">
                                 <div class="card-body">
                                    Glasvervanging voor/achter. Reinigen ventilatiekanalen t.b.v. mechanische ventilatie. Aanbrengen mechanische ventilatie woning. Rookmelders aanbrengen, elektra werkzaamheden. Brandwerende strips voordeuren en
                                    bergingsdeuren. Voorzetwanden plaatsen (alleen kopgevel woningen). Vervangen intercomsysteem. Brandwerende afdichting woning/berging. Tocht profielen aanbrengen/vervangen draaiende delen. Schilderwerk binnen en
                                    buiten.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonnescherm, schotel etc. verwijderen </li>
                                       <li>Raambekleding verwijderen.</li>
                                       <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                                       <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag4" aria-expanded="false" aria-controls="item-2-dag4">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-2-dag4" class="collapse" data-parent="#accordion-dag4">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag4" aria-expanded="false" aria-controls="item-3-dag4">
                                 Toilet
                              </div>
                              <div id="item-3-dag4" class="collapse" data-parent="#accordion-dag4">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-4-dag4" aria-expanded="false" aria-controls="item-4-dag4">
                                 Badkamer
                              </div>
                              <div id="item-4-dag4" class="collapse" data-parent="#accordion-dag4">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag4">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag4">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Raambekleding verwijderen.</li>
                        <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                        <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag5">
               <h3 class="font-weight-light">
                  Maandag 28 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 20%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">20%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag5" aria-controls="werkzaamheden-dag5" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag5" aria-controls="checklist-dag5" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag5" aria-controls="overlast-dag5" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag5">
                     <div class="accordion" id="accordion-dag5">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag5" aria-expanded="false" aria-controls="item-1-dag5">
                                 Badkamer
                              </div>
                              <div id="item-1-dag5" class="collapse" data-parent="#accordion-dag5">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag5" aria-expanded="false" aria-controls="item-2-dag5">
                                 Toilet
                              </div>
                              <div id="item-2-dag5" class="collapse" data-parent="#accordion-dag5">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag5" aria-expanded="false" aria-controls="item-3-dag5">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag5" class="collapse" data-parent="#accordion-dag5">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-4-dag5" aria-expanded="false" aria-controls="item-4-dag5">
                                 Basis werkzaamheden binnen
                              </div>
                              <div id="item-4-dag5" class="collapse" data-parent="#accordion-dag5">
                                 <div class="card-body">
                                    Glasvervanging voor/achter. Reinigen ventilatiekanalen t.b.v. mechanische ventilatie. Aanbrengen mechanische ventilatie woning. Rookmelders aanbrengen, elektra werkzaamheden. Brandwerende strips voordeuren en
                                    bergingsdeuren. Voorzetwanden plaatsen (alleen kopgevel woningen). Vervangen intercomsysteem. Brandwerende afdichting woning/berging. Tocht profielen aanbrengen/vervangen draaiende delen. Schilderwerk binnen en
                                    buiten.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonnescherm, schotel etc. verwijderen </li>
                                       <li>Raambekleding verwijderen.</li>
                                       <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                                       <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag5">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag5">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Raambekleding verwijderen.</li>
                        <li>In de woning moet 1,5 meter vrij zijn vanuit de gevels voor en achter</li>
                        <li>Halletje tussen voordeur en huiskamer moet vrij zijn</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag6">
               <h3 class="font-weight-light">
                  Dinsdag 29 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 24%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">24%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag6" aria-controls="werkzaamheden-dag6" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag6" aria-controls="checklist-dag6" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag6" aria-controls="overlast-dag6" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag6">
                     <div class="accordion" id="accordion-dag6">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag6" aria-expanded="false" aria-controls="item-1-dag6">
                                 Toilet
                              </div>
                              <div id="item-1-dag6" class="collapse" data-parent="#accordion-dag6">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag6" aria-expanded="false" aria-controls="item-2-dag6">
                                 Badkamer
                              </div>
                              <div id="item-2-dag6" class="collapse" data-parent="#accordion-dag6">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag6" aria-expanded="false" aria-controls="item-3-dag6">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag6" class="collapse" data-parent="#accordion-dag6">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag6">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag6">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag7">
               <h3 class="font-weight-light">
                  Woensdag 30 juni
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 28%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">28%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag7" aria-controls="werkzaamheden-dag7" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag7" aria-controls="checklist-dag7" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag7" aria-controls="overlast-dag7" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag7">
                     <div class="accordion" id="accordion-dag7">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag7" aria-expanded="false" aria-controls="item-1-dag7">
                                 Toilet
                              </div>
                              <div id="item-1-dag7" class="collapse" data-parent="#accordion-dag7">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag7" aria-expanded="false" aria-controls="item-2-dag7">
                                 Badkamer
                              </div>
                              <div id="item-2-dag7" class="collapse" data-parent="#accordion-dag7">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag7" aria-expanded="false" aria-controls="item-3-dag7">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag7" class="collapse" data-parent="#accordion-dag7">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag7">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag7">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag8">
               <h3 class="font-weight-light">
                  Donderdag 1 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 32%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">32%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag8" aria-controls="werkzaamheden-dag8" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag8" aria-controls="checklist-dag8" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag8" aria-controls="overlast-dag8" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag8">
                     <div class="accordion" id="accordion-dag8">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag8" aria-expanded="false" aria-controls="item-1-dag8">
                                 Badkamer
                              </div>
                              <div id="item-1-dag8" class="collapse" data-parent="#accordion-dag8">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag8" aria-expanded="false" aria-controls="item-2-dag8">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-2-dag8" class="collapse" data-parent="#accordion-dag8">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag8" aria-expanded="false" aria-controls="item-3-dag8">
                                 Toilet
                              </div>
                              <div id="item-3-dag8" class="collapse" data-parent="#accordion-dag8">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag8">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag8">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag9">
               <h3 class="font-weight-light">
                  Vrijdag 2 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 36%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">36%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag9" aria-controls="werkzaamheden-dag9" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag9" aria-controls="checklist-dag9" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag9" aria-controls="overlast-dag9" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag9">
                     <div class="accordion" id="accordion-dag9">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag9" aria-expanded="false" aria-controls="item-1-dag9">
                                 Badkamer
                              </div>
                              <div id="item-1-dag9" class="collapse" data-parent="#accordion-dag9">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag9" aria-expanded="false" aria-controls="item-2-dag9">
                                 Toilet
                              </div>
                              <div id="item-2-dag9" class="collapse" data-parent="#accordion-dag9">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag9" aria-expanded="false" aria-controls="item-3-dag9">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag9" class="collapse" data-parent="#accordion-dag9">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag9">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag9">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag10">
               <h3 class="font-weight-light">
                  Maandag 5 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 40%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">40%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag10" aria-controls="werkzaamheden-dag10" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag10" aria-controls="checklist-dag10" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag10" aria-controls="overlast-dag10" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag10">
                     <div class="accordion" id="accordion-dag10">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag10" aria-expanded="false" aria-controls="item-1-dag10">
                                 Toilet
                              </div>
                              <div id="item-1-dag10" class="collapse" data-parent="#accordion-dag10">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag10" aria-expanded="false" aria-controls="item-2-dag10">
                                 Badkamer
                              </div>
                              <div id="item-2-dag10" class="collapse" data-parent="#accordion-dag10">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag10" aria-expanded="false" aria-controls="item-3-dag10">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag10" class="collapse" data-parent="#accordion-dag10">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag10">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag10">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag11">
               <h3 class="font-weight-light">
                  Dinsdag 6 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 44%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">44%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag11" aria-controls="werkzaamheden-dag11" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag11" aria-controls="checklist-dag11" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag11" aria-controls="overlast-dag11" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag11">
                     <div class="accordion" id="accordion-dag11">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag11" aria-expanded="false" aria-controls="item-1-dag11">
                                 Badkamer
                              </div>
                              <div id="item-1-dag11" class="collapse" data-parent="#accordion-dag11">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag11" aria-expanded="false" aria-controls="item-2-dag11">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-2-dag11" class="collapse" data-parent="#accordion-dag11">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag11" aria-expanded="false" aria-controls="item-3-dag11">
                                 Toilet
                              </div>
                              <div id="item-3-dag11" class="collapse" data-parent="#accordion-dag11">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag11">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag11">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag12">
               <h3 class="font-weight-light">
                  Woensdag 7 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 48%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">48%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag12" aria-controls="werkzaamheden-dag12" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag12" aria-controls="checklist-dag12" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag12" aria-controls="overlast-dag12" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag12">
                     <div class="accordion" id="accordion-dag12">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag12" aria-expanded="false" aria-controls="item-1-dag12">
                                 Toilet
                              </div>
                              <div id="item-1-dag12" class="collapse" data-parent="#accordion-dag12">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">

                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag12" aria-expanded="false" aria-controls="item-2-dag12">
                                 Badkamer
                              </div>
                              <div id="item-2-dag12" class="collapse" data-parent="#accordion-dag12">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag12" aria-expanded="false" aria-controls="item-3-dag12">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag12" class="collapse" data-parent="#accordion-dag12">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag12">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag12">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag13">
               <h3 class="font-weight-light">
                  Donderdag 8 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 52%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">52%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag13" aria-controls="werkzaamheden-dag13" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag13" aria-controls="checklist-dag13" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag13" aria-controls="overlast-dag13" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag13">
                     <div class="accordion" id="accordion-dag13">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag13" aria-expanded="false" aria-controls="item-1-dag13">
                                 Badkamer
                              </div>
                              <div id="item-1-dag13" class="collapse" data-parent="#accordion-dag13">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">

                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag13" aria-expanded="false" aria-controls="item-2-dag13">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-2-dag13" class="collapse" data-parent="#accordion-dag13">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag13" aria-expanded="false" aria-controls="item-3-dag13">
                                 Toilet
                              </div>
                              <div id="item-3-dag13" class="collapse" data-parent="#accordion-dag13">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag13">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag13">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag14">
               <h3 class="font-weight-light">
                  Vrijdag 9 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 56%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">56%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag14" aria-controls="werkzaamheden-dag14" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag14" aria-controls="checklist-dag14" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag14" aria-controls="overlast-dag14" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag14">
                     <div class="accordion" id="accordion-dag14">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag14" aria-expanded="false" aria-controls="item-1-dag14">
                                 Badkamer
                              </div>
                              <div id="item-1-dag14" class="collapse" data-parent="#accordion-dag14">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag14" aria-expanded="false" aria-controls="item-2-dag14">
                                 Toilet
                              </div>
                              <div id="item-2-dag14" class="collapse" data-parent="#accordion-dag14">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag14" aria-expanded="false" aria-controls="item-3-dag14">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag14" class="collapse" data-parent="#accordion-dag14">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag14">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag14">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag15">
               <h3 class="font-weight-light">
                  Maandag 12 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width:60%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">

                  </div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag15" aria-controls="werkzaamheden-dag15" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag15" aria-controls="checklist-dag15" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag15" aria-controls="overlast-dag15" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag15">
                     <div class="accordion" id="accordion-dag15">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag15" aria-expanded="false" aria-controls="item-1-dag15">
                                 Toilet
                              </div>
                              <div id="item-1-dag15" class="collapse" data-parent="#accordion-dag15">
                                 <div class="card-body">
                                    Strippen van het toilet. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Plaatsen van de wc-pot.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">

                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-2-dag15" aria-expanded="false" aria-controls="item-2-dag15">
                                 Badkamer
                              </div>
                              <div id="item-2-dag15" class="collapse" data-parent="#accordion-dag15">
                                 <div class="card-body">
                                    Strippen van de badkamer. Stucen van de wanden. Aanpassen van de leidingen waar nodig. Betegelen van de wanden. Voegen van de tegels. Installeren van kranen, wasbak etc.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Badkamer moet leeg zijn</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-3-dag15" aria-expanded="false" aria-controls="item-3-dag15">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-3-dag15" class="collapse" data-parent="#accordion-dag15">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag15">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag15">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                        <li>Badkamer moet leeg zijn</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag16">
               <h3 class="font-weight-light">
                  Dinsdag 13 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 64%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">64%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag16" aria-controls="werkzaamheden-dag16" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag16" aria-controls="checklist-dag16" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag16" aria-controls="overlast-dag16" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag16">
                     <div class="accordion" id="accordion-dag16">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag16" aria-expanded="false" aria-controls="item-1-dag16">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag16" class="collapse" data-parent="#accordion-dag16">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag16">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag16">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag17">
               <h3 class="font-weight-light">
                  Woensdag 14 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 68%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">68%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag17" aria-controls="werkzaamheden-dag17" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag17" aria-controls="checklist-dag17" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag17" aria-controls="overlast-dag17" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag17">
                     <div class="accordion" id="accordion-dag17">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag17" aria-expanded="false" aria-controls="item-1-dag17">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag17" class="collapse" data-parent="#accordion-dag17">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag17">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag17">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag18">
               <h3 class="font-weight-light">
                  Donderdag 15 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 72%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">72%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag18" aria-controls="werkzaamheden-dag18" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag18" aria-controls="checklist-dag18" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag18" aria-controls="overlast-dag18" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag18">
                     <div class="accordion" id="accordion-dag18">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag18" aria-expanded="false" aria-controls="item-1-dag18">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag18" class="collapse" data-parent="#accordion-dag18">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag18">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag18">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag19">
               <h3 class="font-weight-light">
                  Vrijdag 16 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 76%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">76%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag19" aria-controls="werkzaamheden-dag19" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag19" aria-controls="checklist-dag19" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag19" aria-controls="overlast-dag19" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag19">
                     <div class="accordion" id="accordion-dag19">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag19" aria-expanded="false" aria-controls="item-1-dag19">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag19" class="collapse" data-parent="#accordion-dag19">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag19">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag19">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag20">
               <h3 class="font-weight-light">
                  Maandag 19 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">80%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag20" aria-controls="werkzaamheden-dag20" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag20" aria-controls="checklist-dag20" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag20" aria-controls="overlast-dag20" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag20">
                     <div class="accordion" id="accordion-dag20">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag20" aria-expanded="false" aria-controls="item-1-dag20">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag20" class="collapse" data-parent="#accordion-dag20">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag20">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag20">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag21">
               <h3 class="font-weight-light">
                  Dinsdag 20 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 87%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">87%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag21" aria-controls="werkzaamheden-dag21" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag21" aria-controls="checklist-dag21" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag21" aria-controls="overlast-dag21" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag21">
                     <div class="accordion" id="accordion-dag21">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag21" aria-expanded="false" aria-controls="item-1-dag21">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag21" class="collapse" data-parent="#accordion-dag21">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag21">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag21">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag22">
               <h3 class="font-weight-light">
                  Woensdag 21 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 88%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">88%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag22" aria-controls="werkzaamheden-dag22" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag22" aria-controls="checklist-dag22" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag22" aria-controls="overlast-dag22" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag22">
                     <div class="accordion" id="accordion-dag22">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag22" aria-expanded="false" aria-controls="item-1-dag22">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag22" class="collapse" data-parent="#accordion-dag22">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag22">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag22">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag23">
               <h3 class="font-weight-light">
                  Donderdag 22 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 92%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">92%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag23" aria-controls="werkzaamheden-dag23" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag23" aria-controls="checklist-dag23" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag23" aria-controls="overlast-dag23" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag23">
                     <div class="accordion" id="accordion-dag23">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag23" aria-expanded="false" aria-controls="item-1-dag23">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag23" class="collapse" data-parent="#accordion-dag23">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag23">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag23">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="werkdag" id="dag24">
               <h3 class="font-weight-light">
                  Vrijdag 23 juli
                  <i class="fal fa-calendar-alt float-right"></i>
               </h3>
               <div class="progress mb-1">
                  <div class="progress-bar bg-secondary" style="width: 96%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">96%</div>
               </div>
               <nav class="nav nav-tabs">
                  <a class="nav-link active" data-toggle="tab" href="#werkzaamheden-dag24" aria-controls="werkzaamheden-dag24" aria-selected="true"><i class="fal fa-user-hard-hat"></i> Werkzaamheden</a>
                  <a class="nav-link" data-toggle="tab" href="#checklist-dag24" aria-controls="checklist-dag24" aria-selected="false"><i class="fal fa-clipboard-list-check"></i> Checklist</a>
                  <a class="nav-link" data-toggle="tab" href="#overlast-dag24" aria-controls="overlast-dag24" aria-selected="false"><i class="fal fa-waveform-path"></i> Overlast</a>
               </nav>
               <div class="tab-content">
                  <div class="tab-pane fade show active" id="werkzaamheden-dag24">
                     <div class="accordion" id="accordion-dag24">
                        <div class="accordion-item">
                           <div class="card">
                              <div class="card-header" data-toggle="collapse" data-target="#item-1-dag24" aria-expanded="false" aria-controls="item-1-dag24">
                                 Buitenwerkzaamheden
                              </div>
                              <div id="item-1-dag24" class="collapse" data-parent="#accordion-dag24">
                                 <div class="card-body">
                                    Saneren panelen. Voegwerk schoorstenen/gevels uithakken.
                                    Houtrot reparatie + Panelen aanbrengen balkons.
                                    Reinigen lateien, gevels, schoorstenen en waterslagen e.d. Dakbedekking en boeiboorden. Opnieuw voegen gevels en schoorstenen.
                                    Betonherstel diktekanten, lateien en luifels.
                                    <h5>Wat moet ik doen</h5>
                                    <ul>
                                       <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                                    </ul>
                                    <h5>Overlast</h5>
                                    Geluid en stof.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="overlast-dag24">
                     <table class="w-100 mt-1">
                        <tr>
                           <td style="width: 100px;">Geluid</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>Stof</td>
                           <td>
                              <div class="progress">
                                 <div class="progress-bar bg-secondary" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                           </td>
                        </tr>
                     </table>
                     <hr class="my-1">
                     <p>U kunt tijdens de werkzaamheden (tijdelijk) geen gebruik maken van:</p>
                     <ul>
                        <li><i class="fal fa-lightbulb-on fa-fw"></i> Elektriciteit</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Kraanwater</li>
                        <li><i class="fal fa-faucet-drip fa-fw"></i> Warm water</li>
                        <li><i class="fal fa-bath fa-fw"></i> Douche</li>
                        <li><i class="fal fa-toilet fa-fw"></i> Toilet</li>
                     </ul>
                  </div>
                  <div class="tab-pane fade" id="checklist-dag24">
                     <p>Wat moet ik doen</p>
                     <ul>
                        <li>Balkon moet leeg zijn. Zonneschermen, schotels e.d. verwijderen.</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </template>

   <script>
   export default {
      name: "House",
      data() {
         return {
            loaded: true,
            user: null
         }
      }
   }
   </script>

   <style scoped>

   </style>
