<template>
  <div class="mt-0">
    <figure class="featured-header">
      <div class="">
        <img v-if="!post.top_image" alt="" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" height="540"
             loading="lazy"
             sizes="(max-width: 1440px) 100vw, 1440px" src="https://unsplash.it/1440/540?&query=office"
             srcset="https://unsplash.it/1440/540 1440w, https://unsplash.it/600/300 600w"
             width="1440">
        <img v-if="post.top_image && post.top_image.url" :src="post.top_image.url" :srcset="post.top_image.url + ' 1440w, ' + post.top_image.url + ' 600w'" alt=""
             class="attachment-post-thumbnail size-post-thumbnail wp-post-image" height="540" loading="lazy"
             sizes="(max-width: 1440px) 100vw, 1440px"
             width="1440">
      </div>
    </figure>
    <div v-if="post" class="wikipage wiki-overview page-content">
      <div class="wikipage-smiley"></div>
      <h1>{{ post.post_title }}</h1>
      <div v-html="parseContent(post.post_content)"></div>
      <div v-if="categories && wikis" class="wiki-categorie-wrapper">
        <section v-for="category in categories" :key="category.cat_ID" class="wiki-categorie">
          <h4>{{ category.name }}</h4>
          <span class="categorie-description">{{ category.description }}</span>
          <ol>
            <li v-for="wiki in getCategoryWikis(category.cat_ID)" :key="wiki.ID">
              <span class="smiley">{{ wiki.smiley }}</span>
              <a href="" @click="$router.push({name:'wiki-item', params: {slug: wiki.post_name} })">{{
                  wiki.post_title
                }}</a>
            </li>
          </ol>
        </section>
      </div>
      <div class="nav-meta">
        <div class="nav-meta-top">
          <span>Suggesties of verbeteringen gevonden? <a href="#">Laat het ons weten!</a></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/babel">
import BaseComponent from './BaseComponent';

export default {
  extends: BaseComponent,
  data() {
    return {
      post: false,
      wikis: false,
      categories: false
    }
  },

  mounted() {
    this.getPage();
    this.getWikis();
  },
  methods: {
    getPage() {
      this.axios.get(window.base_route + '/rutger/v1/pages/1').then(res => {
        this.post = res.data;
      })
    },
    getWikis: function () {
      this.axios.get(window.base_route + '/rutger/v1/wiki-items').then(res => {
        this.wikis = res.data.wikis;
        this.categories = res.data.categories;
      })
    },
    getCategoryWikis: function (cat_id) {
      let data = this.collect([]);
      this.collect(this.wikis).each(function (wiki) {

        wiki.categories.forEach(function (cat) {
          if (cat === cat_id) {
            data.push(wiki);
          }
        });
      });

      return data;
    }
  }
}
</script>
