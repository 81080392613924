<template>
<div v-if="object &&current_user" :class="{'nav-meta' : true, 'bg-white' : hasWhiteBg}" class="nav-meta">
   <div class="nav-meta-top">
      <span>Suggesties of verbeteringen gevonden? <a href="" class="chat-toggle">
            <!-- TODO: on click toggle quick-contact form zie app.js -->Laat het ons weten</a></span>
      <span class="share">Deel dit bericht

         <a :href="'https://api.whatsapp.com/send?text='+current_user.first_name+'%20wil%20deze%20link%20vanuit%20de%20Rutges%20app%20met%20je%20delen%3A%20' + getUrl()" data-html="true" data-placement="top" data-toggle="tooltip" title="Je moet ingelogd zijn om deze pagina te kunnen lezen.<br>Alleen collega's kunnen deze pagina dus zien."
            target="_blank">WhatsApp
            <i class="fab fa-whatsapp"></i></a> of
         <a :href="'mailto:?subject='+current_user.first_name+'%20heeft%20een%20bericht%20met%20je%20gedeeld&body=Hi%2C%0D%0A%0D%0A'+current_user.first_name+'%20heeft%20het%20volgende%20bericht%20met%20je%20gedeeld%3A%0D%0A' + object.post_title+ '%3A%20'+getUrl()+'%0D%0A%0D%0A'"
            data-html="true" data-placement="top" data-toggle="tooltip" title="Je moet ingelogd zijn om deze pagina te kunnen lezen.<br>Alleen collega's kunnen deze pagina dus zien." target="_blank">Mail <i class="fal fa-envelope"></i></a></span>
   </div>

   <div v-if="author" class="post-meta">
      <span class="meta-text">Geschreven door
         <strong>{{ author.name }}</strong> op
         <date-formatter :date="object.post_date"></date-formatter>
      </span>
   </div>
</div>
</template>

<script type="text/babel">
import DateFormatter from './DateFormatter';

export default {
   components: {
      DateFormatter
   },
   props: ['user', 'object', 'whitebg'],

   data() {
      return {
         author: null,
         hasWhiteBg: false,
         current_user: null
      }
   },
   mounted() {

      if (this.whitebg) {
         this.hasWhiteBg = true;
      }

      this.current_user = window.Auth.getUser();

      let self = this;

      this.debounce(function() {
         let the_post = self.object;
         window.console.log(the_post);

         this.axios.get(window.base_route + '/rutger/v1/author/' + the_post.post_author).then(res => {
            self.author = res.data
         })
      }, 20);

   },
   methods: {
      getUrl() {
         let path = location.href;
         return path;
      }
   }
}
</script>
