<template>
  <div id="login" class="login">
    <h3>Log in om door te <span @click="$router.push({name:'debug'})">gaan</span></h3>
    <!-- <p class="login-message login-message-success" v-show="warnings.length"> Je bent nu uitgelogd.</p> -->
    <div v-show="errors.length" id="login_error" class="login-message">
      <span v-for="error in errors" :key="error" v-html="error"></span>
    </div>

    <form id="loginform" method="post" name="loginform" @submit.prevent="submit">
      <div class="form-group">
        <label for="user_login">Gebruikersnaam of e-mailadres</label>
        <input id="user_login" v-model="username" autocapitalize="off" class="form-control" name="log" size="20"
               type="text" value="">
        <!-- <small id="emailHelp" class="form-text text-muted">Deze is gelijk aan die van CBBS.</small> -->
      </div>
      <div class="form-group user-pass-wrap">
        <label for="user_pass">Wachtwoord</label>
        <div class="wp-pwd">
          <input id="user_pass" v-model="password" aria-describedby="login_error" class="form-control password-input"
                 name="pwd" size="20" :type="hide_input ? 'password' : 'text'" value="">
          <span @click="switchVisibility()"><i class="fal fa-eye"></i></span>
        </div>
      </div>
      <div class="custom-control custom-switch">
        <input id="rememberme" class="custom-control-input" name="rememberme" type="checkbox" value="forever">
        <label class="custom-control-label" for="rememberme">Onthoud mij</label>
      </div>
      <div class="submit mt-1">
        <input id="wp-submit" class="btn btn-secondary" name="wp-submit" type="submit" value="Inloggen">
        <input name="redirect_to" type="hidden" value="/posts.php">
      </div>
    </form>
    <div id="nav" class="lostpassword">
      <!--  v-show="!debug"> -->
      <a href="#" @click="$router.push('/wachtwoord-vergeten')">Wachtwoord vergeten?</a> <br><br>
      <p>Heb je nog geen account? <a href="#" @click="$router.push('/registreren')">Maak een account aan</a></p>
      <!-- <p>Heb je nog geen account? Als je actief bent bij Rutges kan de HR een account voor je aanmaken. <a href="mailto:communicatie@rutges.nl?subject=Ik%20wil%20een%20Rutges%20Account&body=Hi%2C%20ik%20heb%20nog%20geen%20account%20bij%20Rutges.%0D%0ADit%20zijn%20mijn%20gegevens%3A%0D%0A-%20Naam%0D%0A-%20Email">Vraag
                  een account aan</a></p> -->
    </div>
  </div>
</template>
<script type="text/babel">
export default {
  data() {
    return {
      username: null,
      password: null,
      redirect_to: '/',
      errors: [],
      warnings: [],
      debug: true,
      hide_input: true
    }
  },
  mounted() {
    if (this.$route.query.redirect) {
      this.redirect_to = this.$route.query.redirect;
    }
  },
  methods: {
    switchVisibility() {
      this.hide_input = !this.hide_input;
    },
    submit() {

      window.jQuery('#user_login').blur();
      window.jQuery('#user_pass').blur();

      this.axios.post(window.base_route + '/jwt-auth/v1/token', {
        username: this.username,
        password: this.password,
      }).then(res => {
        this.errors = [];
        window.Auth.login(res.data.token, {
          user_email: res.data.user_email,
          user_nicename: res.data.user_nicename,
          user_display_name: res.data.user_display_name
        }).then(() => {

          this.$router.push('/');
          location.reload();
        });

      }).catch((exception) => {
        this.errors = [];

        if (exception.response && exception.response.data) {
          this.errors = [exception.response.data.message];
        }
      })
    }
  }
}
</script>
