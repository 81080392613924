<template>
  <strong>{{ this.formatted_date }}</strong>
</template>

<script type="text/babel">
export default {
  props: ['date'],
  data() {
    return {
      formatted_date: null
    }
  },
  mounted() {

    this.formatted_date = this.formatNlDate(this.date);
  },
  methods: {}
}
</script>