<template>
  <div>
    <preloader v-if="!loaded">

    </preloader>

    <div class="post-content d-flex flex-nowrap flex-column" v-if="loaded">
      <div class="container">
        planning
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "House",
  data() {
    return {
      loaded: true
    }
  }
}
</script>

<style scoped>

</style>
