<template>
<div>
   <footer v-if="loaded">
      <div class="container px-0">
         <div class="contact-info">
            <h4>Contact</h4>
            <span class="mb-2">
               <a href="tel:+31612605969" target="_blank"><i class="fal fa-fw fa-mobile-alt"></i> Bel 06 126 059 69</a> <br />
               <a href="mailto:communicatie@rutges.nl" target="_blank"><i class="fal fa-fw fa-paper-plane"></i> E-mail communicatie@rutges.nl</a> <br>
               <a href="https://wa.me/31612605969" target="_blank" v-if="user"><i class="fab fa-fw fa-whatsapp"></i> WhatsApp ons</a>
            </span>
            <span class="mb-2 d-none d-md-inline"><strong>Utrecht</strong><br>Postbus 4006<br />3502 HA Utrecht <br />Damzigt 60<br />3454 PS De Meern</span>
            <span class="mb-2 d-none d-md-inline"><strong>Amsterdam</strong><br>Postbus 67008<br />1060 JA Amsterdam <br />Dukdalfweg 53<br />1041 BC Amsterdam </span>
         </div>
         <div class="social-info d-none d-md-inline">
            <h4>Volg ons</h4>
            <a href="https://www.facebook.com/Rutges-Vernieuwt-272055522930515/" class="animation-delay-all btn float-left btn-secondary icon icon-light fab fa-facebook-f" target="_blank"></a>
            <a href="https://www.twitter.com/RutgesVernieuwt" class="animation-delay-all btn float-left ml-1 btn-secondary icon icon-light fab fa-twitter" target="_blank"></a>
            <a href="https://www.linkedin.com/company/3087836?trk=NUS_CMPY_FOL-pdctd" class="animation-delay-all btn float-left ml-1 btn-secondary icon icon-light fab fa-linkedin-in" target="_blank"></a>
            <a href="https://youtube.com/user/rutgesvernieuwt" class="animation-delay-all btn float-left ml-1 btn-secondary icon icon-light fab fa-youtube" target="_blank"></a>
         </div>
      </div>
      <div class="site-info container px-0">
         <ul class="mb-2">
            <li>© 2021 Rutges BV</li>
            <!--<li><a href="">Privacy verklaring</a></li>
            <li><a href="">Cookie verklaring</a></li>-->
         </ul>
      </div>
   </footer>
   <div class="quick-contact" v-if="user">
      <div class="chat-box">
         <div class="chat-heading">
            <span class="chat-message-title">Hi, Rutges hier👋</span>
            <p class="chat-message-description">Heb je <strong>iets leuks te melden</strong> of heb je <strong>iets gezien wat niet klopt</strong>? Laat van je horen!</p>
         </div>
         <div class="chat-body">
            <div class="chat-container">
               <a href="tel:+31612605969" class="btn btn-secondary btn-block" target="_blank"><i class="fal fa-mobile-alt"></i> Bel <span class="d-inline d-sm-none">ons</span><span class="d-none d-sm-inline">06 126 059 69</span></a>
               <a href="https://wa.me/31612605969" class="btn btn-secondary btn-block" target="_blank"><i class="fab fa-whatsapp"></i> WhatsApp ons</a>
               <a href="mailto:communicatie@rutges.nl" class="btn btn-secondary btn-block" target="_blank"><i class="fal fa-paper-plane"></i> Mail ons</a>
            </div>
            <!-- <div class="chat-container chat-message">
               <strong><i class="fal fa-exclamation-triangle"></i> Covid-19 maatregelen</strong>
               <p><strong>Er mag gevaren worden!</strong> Wel hebben we enkele<a href="/veilig-varen-bij-qrafter-loungebootverhuur/"> veiligheidsmaatregelen</a> genomen.</p>
            </div> -->
         </div>
      </div>
      <div class="chat-button">
         <i class="fal fa-comments"></i>
      </div>
   </div>
</div>
</template>
<script type="text/babel">
export default {
   data() {
      return {
         user: null,
         loggedIn: false,
         loaded: false
      }
   },
   mounted() {
      this.user = window.Auth.getUser();
      this.loaded = true
   }
}
</script>
