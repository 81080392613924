<template>
<div class="calendar">
   <nav>
      <span class="calendar-title">Rutges kalender</span>
   </nav>
   <div v-for="(month, key) in calendar_items" :key="key" class="month">
      <div class="month-title">
         {{ getMonthName(key) }}
      </div>
      <div v-for="(day, daykey) in month" :key="daykey" class="day">
         <div class="day-title"> {{ getDayName(key, daykey) }}</div>
         <div v-for="item in sortedItems(day)" v-show="(item.start_tijd && item.eind_tijd) || (item.data && item.data.date)" :key="item.ID" :class="{'day-item': true, 'event': item.start_tijd && item.eind_tijd, 'birthday': item.data && item.data.date }">
            <div class="day-item-time">
               <span v-show="(item.data && item.data.date) || item.hele_dag">hele dag</span>
               <span v-show="item.start_tijd && item.eind_tijd && !item.hele_dag">
                  <span class="day-item-time-start">{{ item.start_tijd }}</span>
                  <span class="day-item-finish">{{ item.eind_tijd }}</span>
               </span>
            </div>
            <div class="day-item-description">
               {{ item.post_title ? item.post_title : item.data.display_name + ' is jarig' }}
            </div>
         </div>
      </div>
   </div>
</div>
</template>

<script>
export default {
   name: "Calendar.vue",
   data() {
      return {
         calendar_items: [],
         calendar_items2: [],
         months: []
      }
   },
   mounted() {
      this.getCalendarItems();
      
      
   },

   methods: {
      getCalendarItems() {
         this.axios.get(window.base_route + '/rutger/v1/calendar').then(res => {
            this.calendar_items = res.data;

            window.scrollTo(0,0);
         });
      },
      getMonthName(key) {

         const event = new Date(Date.UTC(key.substr(0, 4), (parseInt(key.substr(4, 2)) - 1), 1, 0, 0, 0));

         const options = {
            year: 'numeric',
            month: 'long'
         };

         return this.capitalize(event.toLocaleDateString('nl-NL', options));
      },

      getDayName(monthkey, daykey) {

         const event = new Date(Date.UTC(monthkey.substr(0, 4), (parseInt(monthkey.substr(4, 2)) - 1), daykey, 0, 0, 0));

         const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric'
         };

         return this.capitalize(event.toLocaleDateString('nl-NL', options));
      },
      capitalize(s) {
         if (typeof s !== 'string') return ''
         return s.charAt(0).toUpperCase() + s.slice(1)
      },
      sortedItems(day) {
         return this.collect(day).sortBy('sortable_date').toArray();
      }
   }

}
</script>

<style scoped>

</style>
