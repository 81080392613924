<template>
<div class="post-content">
   <div class="preloader">
      <div class="preloader-animation">
         <div id="shadow"></div>
         <div id="box"></div>
      </div>
      <span class="preloader-text">Laden</span>
   </div>
</div>
</template>

<script>
export default {
   name: "Preloader"
}
</script>

<style scoped>

</style>
