<template>
  <div id="app">
    <the-header v-if="!this.$route.meta.alternative_header"></the-header>
    <alternative-header v-if="this.$route.meta.alternative_header"></alternative-header>
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from './Footer'
import TheHeader from './Header'
import AlternativeHeader from './AlternativeHeader'

export default {
  name: 'app',
  components: {
    TheFooter,
    TheHeader,
    AlternativeHeader
  },
  data: function () {
    return {
      alternative_header: false
    }
  },
  mounted() {
    if (window.location.href.indexOf('set_app=true') > 0) {
      window.localStorage.setItem('isApp', true);
    }

    if(window.localStorage.getItem('isApp')) {
      if(!window.jQuery('#app').hasClass('ionic')) {
        window.jQuery('#app').addClass('ionic');
      }
    }
  }
}
</script>
