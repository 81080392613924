<template>
    <div>
        <div class="user" v-if="user">
            <h1>Jouw account</h1>

            <div class="user-content" v-if="loaded">
                <div class="row">
                    <form id="account-form" class="col-12 col-md" method="post">
                        <h3>Account</h3>
                        <div class="form-group">
                            <label for="username">Gebruikersnaam</label>
                            <input id="username" autocapitalize="off" class="form-control" disabled="disabled"
                                   name="log" size="20" type="text" v-model="user.name">
                            <small id="emailHelp" class="form-text text-muted">Je gebruikersnaam kan niet worden
                                gewijzigd </small>
                        </div>
                        <div class="form-group">
                            <label for="birthday">Verjaardag</label>
                            <the-mask :mask="['##/##/####']" class="form-control" name="birthday" type="text"
                                      placeholder="dd/mm/yyyy" v-model="user.birthday" required/>
                        </div>
                        <div class="custom-control custom-switch">
                            <input id="rememberme" class="custom-control-input" name="rememberme" type="checkbox"
                                   value="forever" v-model="user.hide_birthday">
                            <label class="custom-control-label" for="rememberme">Verberg mijn verjaardag in de
                                kalender</label>
                        </div>
                    </form>
                    <hr>
                    <form class="col-12 offset-md-1 col-md" method="post">
                        <h3>Wachtwoord veranderen?</h3>
                        <div class="form-group">
                            <label for="currentpass">Je huidige wachtwoord</label>
                            <input id="currentpass" v-model="oldpw" autocapitalize="off" class="form-control" name="log"
                                   size="20" type="password" value="">
                        </div>
                        <div class="form-group">
                            <label for="newpass">Je nieuwe wachtwoord</label>
                            <input id="newpass" v-model="newpw" autocapitalize="off" class="form-control" name="log"
                                   size="20" type="password" value="">
                        </div>
                        <div class="form-group">
                            <label for="repeat-newpass">Herhaal je nieuwe wachtwoord</label>
                            <input id="repeat-newpass" v-model="newpw_confirm" autocapitalize="off" class="form-control"
                                   name="log" size="20" type="password" value="">
                        </div>
                        <div class="submit mt-1">
                            <input id="wp-submit" class="btn btn-secondary" name="wp-submit" type="button"
                                   value="Wijzigingen opslaan" @click="save">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import {TheMask} from 'vue-the-mask';

    export default {
        components: {TheMask},
        data() {
            return {
                user: null,
                loaded: false,
                oldpw: null,
                newpw: null,
                newpw_confirm: null,
            }
        },
        mounted() {
            // this.user = JSON.parse(window.localStorage.getItem('user'));
            try {
                this.user = JSON.parse(window.localStorage.getItem('user'));
            }catch (e) {
                this.user = window.localStorage.getItem('user');
            }
            this.enrichUser();
        },
        methods: {
            enrichUser() {

              window.console.log('enricht it');
              window.console.log(this.user);
                window.axios.post(window.base_route + '/rutger/v1/me/info', {
                    email: this.user.email
                }).then(res => {

                    this.user.birthday = res.data.birthday;
                    this.user.hide_birthday = res.data.hide_birthday;
                    this.loaded = true;
                });
            },
            save() {
                if (this.newpw !== this.newpw_confirm) {
                    window.alert('Wachtwoorden zijn niet gelijk');
                    return;
                }
                this.loaded = false;
                window.axios.post(window.base_route + '/rutger/v1/me/update', {
                    email: this.user.email,
                    userdata: this.user,
                    oldpw: this.oldpw,
                    newpw: this.newpw
                }).then((res) => {
                    window.console.log(res.data);
                    this.loaded = true;
                });
            }

        },

    }
</script>
