<template>
  <div>
    <preloader v-if="!loaded">

    </preloader>
    <div class="container" v-if="loaded">
      <div v-if="albums" class="multiple-photoalbum-container">
        <private-item v-if="!loggedIn"></private-item>
        <a v-for="album in albums" :key="album.ID" :href="'/album/'+album.slug" class="photo-album-preview">
          <div class="photo-album-image-wrapper">
            <img :alt="album.post_title" :src="getAlbumCoverImage(album.ID)" height="600" loading="lazy" width="600">
          </div>
          <span class="album-title">{{ album.post_title }}</span>
          <span class="album-size">{{ album.gallery_count }} foto's</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import PrivateItem from "./PrivateAlbum";

export default {
  components: {
    Preloader,
    PrivateItem
  },
  name: "PhotoAlbums.vue",
  data() {
    return {
      albums: [],
      loaded: false,
      loggedIn: false,
      user: null
    }
  },
  mounted() {
    this.getAlbums();
    this.user = window.Auth.getUser();
    if (this.user) {
      this.loggedIn = true;
    }
    window.scrollTo(0, 0);
  },
  methods: {
    getAlbums() {
      this.axios.get(window.base_route + '/rutger/v1/albums').then(res => {
        this.albums = res.data;
        this.loaded = true;
      });
    },
    getAlbumCoverImage(album_id) {
      let url = 'https://unsplash.it/600/600?&query=office'
      this.collect(this.albums).each(album => {
        if (album.ID == album_id) {
          if (album.gallery[0]) {
            url = album.gallery[0].preview_image;
          }
        }
      });

      return url;
    }
  }

}
</script>
