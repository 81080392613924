// import AOS from 'aos';
import './assets/styles/style.scss';

require('bootstrap');
require('slick-carousel');

// require ('simplelightbox/dist/simple-lightbox.jquery.min')
// require('simplelightbox/dist/simple-lightbox.min.css')
// require('ekko-lightbox');

// require('./assets/lib/fancybox');


require('@fancyapps/fancybox/dist/jquery.fancybox.js');
require('./assets/lib/fancybox.css');

// Bootstrap Tooltip
window.jQuery(function () {
    window.jQuery('[data-toggle="tooltip"]').tooltip()
});

// // Simsalabim
// window.jQuery(".post-content > *").attr("data-aos","fade-up");
window.jQuery(".blocks-gallery-item a[href]").attr("data-gallery", "gallery");

// window.jQuery(document).on('click', '[data-toggle="lightbox"]', function(event) {
//     event.preventDefault();
//     window.jQuery(this).simpleLightbox();
//     // window.jQuery(this).ekkoLightbox();
// });

window.onload = function(){
     setTimeout("scrollTo(0,1)", 100);
}

// Slick slider staat op home.vue
// window.jQuery('.featured-posts-home').slick({
//     dots: true,
//     infinite: true,
//     speed: 300,
//     mobileFirst: true,
//     autoplay: true,
//     autoplaySpeed: 100,
//     arrows: false,
//     responsive: [{
//         breakpoint: 768,
//         settings: "unslick"
//     }]
// });


// Animate on scroll library
// https://github.com/michalsnik/aos#animations
// AOS.init({
//     // Global settings:
//     debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
//     throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
//
//     offset: 50, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 800, // values from 0 to 3000, with step 50ms
//     easing: 'ease', // default easing for AOS animations
//     once: true, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
//
// });

var imagesLoaded = require('imagesloaded');
// Preload images
const preloadImages = () => {
    return new Promise((resolve, reject) => {
        imagesLoaded(document.querySelectorAll('img'), resolve);
    });
};
// And then..
preloadImages().then(() => {
    // Remove the loader
    // document.body.classList.remove('loading');
    // const effect = new StretchEffect(container, itemsWrapper)
});

// CHAT BUTTON
window.jQuery(function () {
    var burger = document.querySelector('.chat-button'),
        header = document.querySelector('.quick-contact');
    if (burger !== null) {
        burger.onclick = function () {
            header.classList.toggle('show');
        }
    }
});

// TODO
// CHAT BUTTON
// On click 'chat-toggle' » Toggle class 'show' on quick-contact
// Check ook MetaFooter.vue

// password visibility
// On click switchVisibility, show password at login.vue
// https://codepen.io/ivansieder/pen/yvjzXP?editors=1010
