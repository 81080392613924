<template>
  <div class="post-content">
    <h1>Zoek resultaten voor "{{ query }}"</h1>
    <div class="searchresults">
      <a v-for="result in results" :key="result.id" href="#" @click="openResult(result)">
        {{ result.post_title }}
      </a>

      <span v-if="!results || !results.length">
              Geen resultaten gevonden
           </span>
    </div>
  </div>
</template>

<script type="text/babel">
export default {

  data() {
    return {
      query: null,
      results: []
    }
  },
  mounted() {
    this.query = this.$route.params.query;

    this.search();
  },
  beforeRouteUpdate(to, from, next) {
    this.query = to.params.query;
    this.search();
    next()
  },
  methods: {
    search() {
      this.axios.post(window.base_route + '/rutger/v1/search', {
        query: this.query
      }).then(res => {
        this.results = res.data;
      })
    },
    openResult(result) {
      window.console.log(result);
      switch (result.post_type) {
        default:
          this.$router.push('/' + result.post_name);
          break;
        case 'shorts':
          this.$router.push('/' + result.post_name);
          break;
        case 'wiki':
          this.$router.push('/wiki/' + result.slug);
          break;
      }

    }
  }
}
</script>
