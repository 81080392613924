<template>
  <div>
  </div>
</template>
<script type="text/babel">
export default {
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    handleInternalLinks: function () {
      window.$('a').each(function () {
        if (window.$(this).attr('href')) {
          window.console.log(window.$(this).attr('href'));
          window.$(this).on('click', function (e) {
            e.preventDefault();
            alert('click')
          })
        }
      })
    }
  }
}
</script>
