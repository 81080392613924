<template>
<div class="post-overview">
   <div v-if="featured_posts" class="featured-posts">
      <post-item v-for="post in featured_posts" :key="post.id" :featured="true" :post="post"></post-item>
      <private-item v-if="!loggedIn"></private-item>
      <post-item v-for="post in posts" :key="post.id" :post="post"></post-item>
   </div>
</div>
</template>

<script type="text/babel">
import PostItem from './PostItem';
import PrivateItem from "./PrivateItem";

export default {
   components: {
      PostItem,
      PrivateItem
   },
   data() {
      return {
         posts: [],
         user: null,
         featured_posts: [],
         loggedIn: false
      }
   },
   mounted() {
      this.user = window.Auth.getUser();
      this.getFeaturedPosts();
      this.getPosts();
      if(this.user) {
         this.loggedIn = true;
      }
      window.scrollTo(0,0);
   },
   methods: {
      getPosts: function() {
         this.axios.get(window.base_route + '/rutger/v1/posts').then(res => {
            this.posts = res.data;
         })
      },

      getFeaturedPosts: function() {
         this.axios.get(window.base_route + '/rutger/v1/featured-posts').then(res => {
            this.featured_posts = res.data;
         })
      },
   }
}
</script>
