<template>
<div id="login" class="login">
   <h3>Wachtwoord vergeten</h3>
   <p>
      Voer je gebruikersnaam of e-mail adres in om je wachtwoord te herstellen.
   </p>
   <!-- <p class="login-message login-message-success" v-show="warnings.length"> Je bent nu uitgelogd.</p> -->
   <div v-show="errors.length" id="login_error" class="login-message">
      <span v-for="error in errors" :key="error" v-html="error"></span>
   </div>
   <form id="loginform" method="post" name="loginform" @submit.prevent="submit">
      <div class="form-group">
         <label for="user_login">Gebruikersnaam of e-mailadres</label>
         <input id="user_login" v-model="username" autocapitalize="off" class="form-control" name="log" size="20" type="text" value="">
      </div>
      <div class="submit mt-1">
         <input id="wp-submit" class="btn btn-secondary" name="wp-submit" type="submit" value="Inloggen">
      </div>
   </form>
</div>
</template>

<script>
export default {
   name: "ForgotPassword",
   data() {
      return {
         username: null,
         errors: []
      }
   },
   methods: {
      submit() {
         this.errors = [];
         this.axios.post(window.base_route + '/rutger/v1/forgot-password', {
            username: this.username
         }).then(() => {
            let self = this;
            self.errors = ['Wanneer je gebruikersnaam of e-mail klopt dan onvang je een e-mail met verdere instructies. Over een paar seconden sturen we je automatisch door naar de login pagina.'];
            setTimeout(function() {
               self.$router.push('/login');
            }, 10000)

         }).catch((exception) => {
            if (exception.response && exception.response.data) {
               this.errors = [exception.response.data.message];
            }
         });
      }
   }
}
</script>

<style scoped>

</style>
