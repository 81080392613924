import Vue from 'vue'
import App from './App.vue'
import router from './routes';
import VueRouter from 'vue-router';
import axios from 'axios';
import collect from 'collect.js';
import VueTimeago from 'vue-timeago';
import moment from 'moment';
import Auth from './auth';
import {VueMasonryPlugin} from 'vue-masonry';
import VueGtag from "vue-gtag";

window.cookies = require('js-cookie');
import FingerprintJS from '@fingerprintjs/fingerprintjs';

Vue.prototype.axios = axios;
window.axios = axios;
window.base_route = "https://admin.rutges.nl/?rest_route=";
window.base_url = "https://admin.rutges.nl/";

    (async () => {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        window.visitorId = result.visitorId;
        window.localStorage.setItem('visitorId', result.visitorId);

        if ((window.localStorage.getItem('token') === 'null' && window.localStorage.getItem('user') === 'null') ||
            (window.localStorage.getItem('token') === null && window.localStorage.getItem('user') === null)){
            window.axios.post(window.base_route + '/rutger/v1/check-fingerprint', {fingerprint: result.visitorId}).then((res) => {
                if (res.data.token !== 'notfound') {
                    let user = res.data.user

                    window.localStorage.setItem('token', res.data.token);
                    window.localStorage.setItem('user', JSON.stringify(user));
                    window.cookies.set('token', res.data.token, {expires: 365});
                    window.cookies.set('user', user, {expires: 365});

                }
            })
        }

    })();

Vue.use(VueGtag, {
    config: {id: "G-YVMWFZBMR2"}
});

Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'nl', // Default locale
    locales: {
        'nl': require('date-fns/locale/nl'),
    }
});
window._ = require('lodash');
window.cookies.set('first_set', {first_set: true});
Vue.use(VueMasonryPlugin)

Vue.config.productionTip = false;
Vue.use(VueRouter);
window.$ = window.jQuery = require('jquery');


Vue.prototype.collect = collect;

window.Auth = new Auth();

Vue.prototype.parseContent = function (the_content) {
    the_content = replaceAll(the_content, window.base_url, '/');

    return the_content;
};

function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

Vue.prototype.debounce = function (callback, time_in_ms) {
    setTimeout(callback, time_in_ms);
};

Vue.prototype.formatNlDate = function (value) {
    if (value) {
        moment.locale('nl');
        return moment(String(value)).format('LL');
    }
}

Vue.prototype.dateStringToDateObject = function(value) {
    if(value) {
        return moment(String(value));
    }
}

require('./app.js');

new Vue({
    render: h => h(App),
    router
}).$mount('#app');

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
