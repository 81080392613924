<template>
<div class="shortpost-content shortpost-content-private" @click="$router.push({name:'login'})">
   <span class="smiley">🙈</span>
   <div class="content">
      <span class="publish-date">
         de grote dag <i class="fal fa-clock"></i>
      </span>
      <h3>Hé boef deze titel is onleesbaar</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce consectetur dolor eu diam dapibus, vel ultricies augue semper. Vivamus enim metus, mollis in purus non, euismod pellentesque urna. In iaculis quis dolor a sagittis.
         Pellentesque et augue vestibulum, dictum ex id, malesuada turpis. </p>
      <figure>
         <div>
            <img src="https://loremflickr.com/384/256/smiling" alt="" class="" loading="lazy" sizes="(max-width: 1200px) 100vw, 1200px">
         </div>
      </figure>
      <div class="shortpost-content-private-message">
         <i class="fal fa-lock mb-1"></i>
         <strong>Deze en nog veel meer berichten lezen?</strong>
         <span class="mb-2">Log in of maak een gebruiker aan »</span>
      </div>
   </div>
</div>
</template>

<script>
export default {
   name: "PrivateItemShortpost"
}
</script>

<style scoped>

</style>
