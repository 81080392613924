import VueRouter from 'vue-router';

let routes = [
    {
        path: '/',
        component: require('./components/Home.vue').default,
        name: 'homepage',
        meta: {middlewareAuth: false, title: 'Homepage'}
    },
    {
        path: '/berichten',
        component: require('./components/Posts.vue').default,
        name: 'posts',
        meta: {middlewareAuth: false, title: 'Berichten'}
    },
    {
        path: '/wiki',
        component: require('./components/Wiki.vue').default,
        name: 'wiki',
        meta: {middlewareAuth: true, title: 'Wiki'}
    },
    {
        path: '/wiki/:slug',
        component: require('./components/WikiItem.vue').default,
        name: 'wiki-item',
        meta: {middlewareAuth: true, title: 'Wiki bericht'}
    },
    {
        path: '/login',
        component: require('./components/Login.vue').default,
        name: 'login',
        meta: {middlewareAuth: false, title: 'Inloggen'}
    },
    {
        path: '/user',
        component: require('./components/User.vue').default,
        name: 'user',
        meta: {middlewareAuth: true, title: 'Profiel'}
    },
    {
        path: '/registreren',
        component: require('./components/NewUser.vue').default,
        name: 'register',
        meta: {middlewareAuth: false, title: 'Registreren'}
    },
    {
        path: '/wachtwoord-vergeten',
        component: require('./components/ForgotPassword.vue').default,
        name: 'forgot_password',
        meta: {middlewareAuth: false, title: 'Wachtwoord vergeten'}
    },
    {
        path: '/wachtwoord-vergeten/reset',
        component: require('./components/ForgotPasswordReset.vue').default,
        name: 'forgot_password_reset',
        meta: {middlewareAuth: false, title: 'Wachtwoord vergeten'}
    },
    {
        path: '/wp-admin',
        component: require('./components/RedirectToAdmin.vue').default,
        name: 'redirect-to-admin',
        meta: {middlewareAuth: true, title: 'Redirect to Admin'}
    },
    {
        path: '/kalender',
        component: require('./components/Calendar.vue').default,
        name: 'kalender',
        meta: {middlewareAuth: true, title: 'Kalender'}
    },
    {
        path: '/woning',
        component: require('./components/House.vue').default,
        name: 'woning',
        meta: {middlewareAuth: true, title: 'Woning'}
    },
    {
        path: '/woning/planning',
        component: require('./components/HousePlanning.vue').default,
        name: 'woning_planning',
        meta: {middlewareAuth: true, title: 'Woning Planning'}
    },

    // {
    //     path: '/masonry',
    //     component: require('./components/Masonry.vue').default,
    //     name: 'Masonry',
    //     meta: {middlewareAuth: true, title: 'Masonry'}
    // },
    {
        path: "/404",
        component: require('./components/PageNotFound.vue').default,
        meta: {middlewareAuth: false, title: 'Pagina niet gevonden'}
    },
    {
        path: '/:slug',
        component: require('./components/SinglePost.vue').default,
        name: 'single-post',
        meta: {middlewareAuth: false, title: 'Bericht', alternative_header: true}
    },
    {
        path: '/zoeken/:query',
        component: require('./components/SearchResults.vue').default,
        name: 'search',
        meta: {middlewareAuth: false, title: 'Zoeken'}
    },
    {
        path: '/album/overzicht',
        component: require('./components/PhotoAlbums.vue').default,
        name: 'photo_albums',
        meta: {middlewareAuth: false, title: 'Foto Albums'}
    },
    {
        path: '/album/:slug',
        component: require('./components/PhotoAlbum.vue').default,
        name: 'photo_album',
        meta: {middlewareAuth: false, title: 'Foto Album'}
    },
    {
        path: '/debug',
        component: require('./components/Debug.vue').default,
        name: 'debug',
        meta: {middlewareAuth: false, title: 'Debug'}
    },

    {
        path: "*",
        component: require('./components/PageNotFound.vue').default,
        meta: {middlewareAuth: false, title: 'Pagina niet gevonden'}
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: 'current_page_item '
});


router.beforeEach((to, from, next) => {

    if (to.query.token && to.query.user) {

        window.localStorage.setItem('token', to.query.token);
        window.localStorage.setItem('user', to.query.user);
    }

    if (to.matched.some(record => record.meta.middlewareAuth)) {
        if (!window.Auth.check()) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            });

            return;
        }
    }


    if (to.meta.title) {
        document.title = "Rutges | " + to.meta.title;
    } else {
        document.title = "Rutges";
    }

    next();
});


export default router;
