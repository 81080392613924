<template>
<div id="login" class="login">
   <h3>Wachtwoord vergeten</h3>
   <p>
      Voer uw nieuwe wachtwoord in
   </p>
   <!-- <p class="login-message login-message-success" v-show="warnings.length"> Je bent nu uitgelogd.</p> -->
   <div v-show="errors.length" id="login_error" class="login-message">
      <span v-for="error in errors" :key="error" v-html="error"></span>
   </div>
   <form id="loginform" method="post" name="loginform" @submit.prevent="submit">
      <div class="form-group user-pass-wrap">
         <label for="user_pass">Wachtwoord</label>
         <div class="wp-pwd">
            <input id="user_pass" v-model="password" aria-describedby="login_error" class="form-control password-input" name="pwd" size="20" type="password" value="">
         </div>
      </div>
      <div class="submit mt-1">
         <input id="wp-submit" class="btn btn-secondary" name="wp-submit" type="submit" value="Inloggen">
      </div>
   </form>

</div>
</template>

<script>
export default {
   name: "ForgotPasswordReset",
   data() {
      return {
         password: null,
         errors: [],
         email: null,
         key: null
      }
   },
   mounted() {
      this.key = this.$route.query.key;
      this.email = this.$route.query.email;
   },
   methods: {
      submit() {
         this.errors = [];
         this.axios.post(window.base_route + '/rutger/v1/forgot-password/reset', {
            password: this.password,
            key: this.key,
            email: this.email
         }).then(() => {
            let self = this;
            self.errors = ['Uw wachtwoord is gereset, u kunt nu met deze gegevens inloggen. Over een aantal seconden wordt u automatisch doorgestuurd naar het login scherm.'];
            setTimeout(function() {
               self.$router.push('/login');
            }, 5000)

         }).catch((exception) => {
            if (exception.response && exception.response.data) {
               this.errors = [exception.response.data.message];
            }
         });
      }
   }
}
</script>

<style scoped>

</style>
