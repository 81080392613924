<template>
  <header id="top">
    <router-link to="/" :class="'logo'"><img src="/dist/images/logo-rutger.svg"></router-link>
    <nav class="nav-global">
      <ul>
        <router-link tag="li" to="/" exact><a><i class="fal fa-home-lg"></i> Startpagina</a></router-link>
        <router-link tag="li" to="/berichten"><a><i class="fal fa-newspaper"></i> Berichten</a></router-link>
        <router-link tag="li" to="/album/overzicht"><a><i class="fal fa-images"></i> Foto's</a></router-link>
        <router-link tag="li" to="/kalender" v-if="user"><a><i class="fal fa-calendar-alt"></i> Kalender</a>
        </router-link>
        <router-link tag="li" to="/woning" v-if="showMenuOption()"><a><i class="fal fa-clipboard-list"></i> Jouw woning</a>
        </router-link>
      </ul>
    </nav>
    <nav class="nav-utility">
      <ul>
        <li class="search" v-if="user">
          <form @submit.prevent="searchQuery()">
            <input type="search" v-model="query" class="search-input"><i class="fal fa-search"></i>
          </form>
        </li>
        <li class="notifications" v-if="user">
          <a :class="{'notification-active': notifications.length >0}" href="#" role="button" id="notifications"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa-fw fal fa-bell" v-if="showNotificationBell()"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="notifications">
            <a class="dropdown-item" v-for="notification in notifications"
               :href="notification.link ? notification.link.url : '#'" :key="notification.ID"
               v-html="notification.post_content">
            </a>
            <span v-if="notifications.length === 0" class="dropdown-item">Geen meldingen</span>
          </div>
        </li>
        <li class="account">
          <a class="dropdown-toggle" href="#" role="button" id="userOptions" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false">
            <i class="fa-fw fal fa-user"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="userOptions" v-if="user">
            <a class="dropdown-item" href="/user/"><i class="fa-fw fal fa-user"></i> Mijn account</a>
            <a class="dropdown-item" @click="logOff"><i class="fa-fw fal fa-sign-out-alt"></i> Uitloggen</a>
          </div>
          <div class="dropdown-menu" aria-labelledby="userOptions" v-if="!loggedIn">
            <a class="dropdown-item" @click="$router.push({name:'login'})"><i class="fa-fw fal fa-sign-in-alt"></i>
              Inloggen</a>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>
<script type="text/babel">
export default {
  data() {
    return {
      query: this.$route.params.query,
      user: null,
      notifications: [],
      loggedIn: false
    }
  },
  mounted() {
    this.user = window.Auth.getUser();
    this.getNotifications();

    if (this.user) {
      this.loggedIn = true;
    }
  },
  methods: {
    searchQuery() {
      this.$router.push({
        name: 'search',
        params: {
          query: this.query
        }
      });
    },
    logOff() {
      window.Auth.logout(true);
    },
    getNotifications() {
      if (!this.user) {
        return;
      }
      this.axios.get(window.base_route + '/rutger/v1/notifications').then(res => {
        this.notifications = res.data;
      });
    },

    showNotificationBell() {
      return true;
    },
    showMenuOption() {
      if (!this.user) {
        return false;
      }
      let allowed_users = [
        2,
        129
      ];
      if (allowed_users.includes(this.user.ID)) {
        return true;
      }
      return false;
    }


  }
}
</script>
