<template>
<a class="post-item post-item-private" href="" @click="$router.push({name:'login'})">
   <img src="https://loremflickr.com/384/256/builder" alt="" class="" height="675" loading="lazy" sizes="(max-width: 1200px) 100vw, 1200px" width="1200" />
   <div :class="{featured:featured}" class="featured-posts-content">
      <span class="publish-date"></span>
      <div class="featured-posts-content-private">
         <i class="fal fa-lock mb-1"></i>
         <strong>Deze en nog veel meer berichten lezen?</strong>
         <span class="mb-2">Log in of maak een gebruiker aan »</span>
      </div>
      <h2>Je wilt graag weten wat hier staat? Log-in voor deze en nog veel meer berichten</h2>
   </div>
</a>
</template>

<script>
export default {
   name: "PrivateItem",
   data() {
      return {
         featured: false
      }
   }
}
</script>

<style scoped>

</style>
